import angular from 'angular';
export default angular.module('httpErrorInterceptor', []).factory('HttpErrorInterceptor', ['$q', function ($q) {
        return {
            'requestError': function (rejection) {
                console.log('httpErrorInterceptor: requestError');
                return $q.reject(rejection);
            },
            'responseError': function (rejection) {
                var _a;
                if (rejection.status == 401) {
                    window.alert('Kirjautuminen on vanhentunut tai et ole kirjautunut sisään. Ole hyvä ja kirjaudu uudelleen.');
                    window.location.href = "../";
                    return $q.reject(rejection);
                }
                if (rejection.status == 403) {
                    window.alert('Käyttöoikeudet eivät riitä toiminnon suorittamiseen.\n\nTarvittaessa ota yhteyttä helpdesk@matriset.fi.');
                    return $q.reject(rejection);
                }
                if (rejection.status == 404 && rejection.config.ignoreNotFound) {
                    return $q.reject(rejection);
                }
                if (rejection.config && rejection.config.noIntercept && rejection.config.noIntercept.indexOf(rejection.status) != -1) {
                    return $q.reject(rejection);
                }
                if (rejection.status === 0 || rejection.status === -1) {
                }
                else if (rejection.status) {
                    let messages = [`${rejection.config.method} ${rejection.config.url}`];
                    if (((_a = rejection.data) === null || _a === void 0 ? void 0 : _a.resourceType) === 'TKR_ERROR') {
                        messages.push(`\nVirhekoodi ${rejection.data.errorCode} (status ${rejection.status})\n`);
                        messages.push(rejection.data.errorMessage);
                    }
                    else {
                        messages.push(`ERROR ${rejection.status}`);
                    }
                    window.alert(messages.join('\n'));
                }
                else {
                    window.alert('Response parse ERROR -> contact helpdesk@matriset.fi');
                }
                return $q.reject(rejection);
            }
        };
    }]).config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('HttpErrorInterceptor');
    }])
    .name;
